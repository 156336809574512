.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  font-family: Roboto;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: #0099e9;
  }

  &:focus {
    outline: none;
  }
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #0099e9;
}

.rbc-event-label {
  font-size: 80%;
  display: none;
}

.rbc-event-overlaps {
  box-shadow: none;
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: center;
  overflow: visible;
  z-index: 0;
  padding: 0;
  position: absolute;
  font-weight: bold;
}
