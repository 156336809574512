@import "../../../../shared/Variables.scss";

.navigation-item-wrapper {
  margin: 0 2px;
  text-decoration: none;
  .navigation-item {
    text-decoration: none;
    color: black;
    font-weight: 700;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;

    &:hover {
      background-color: $sro-grey;
    }
  }

  &.active {
    border-bottom: 1px solid $sro-color;
  }
}
