.contact-info {
  margin-top: 40px;
  &--title {
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid #f3f5f6;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  &--inputs {
    display: flex;
    gap: 5%;
    margin-top: 30px;
    padding-left: 5px;
  }
}
