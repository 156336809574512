.add-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  &--error {
    color: red;
    font-size: 16px;
    font-weight: 400;
  }
}
