@import "../../../../../shared/Variables.scss";

.planned-event {
  padding: 20px;
  border: 2px solid $sro-color;
  box-sizing: border-box;
  border-radius: 10px;
  width: 200px;
  height: fit-content;
  min-height: 100px;
  max-height: 200px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  &:hover .planned-event--overlay {
    display: flex;
  }

  &--overlay {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    gap: 50px;
    background-color: #fffffff1;
    z-index: 10;
    display: none;
    align-items: center;
    justify-content: center;

    i {
      cursor: pointer;
      font-size: 20px;
    }
  }

  &--title {
    margin: 0;
    margin-bottom: 5px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }

  &--info {
    margin: 0;
    font-weight: 400;
  }
}
