.overview-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  width: 1640px; //1440 is the miutes in 24 hrs and +200 because of name

  &--event {
    display: flex;
    align-items: center;
    &__name {
      color: white;
      font-weight: 500;
      font-size: 12px;
      width: 100%;
      padding: 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--name {
    width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 30px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1;
  }

  &--event {
    height: 30px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box
  }

  &--time-between {
    height: 30px;
    background-color: #e5e5e5;
    opacity: 30%;
    cursor: default;
    border-radius: 5px;
  }
}
