.popup-wrapper {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;

  .popup {
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    position: relative;
    max-height: 90vh;
    overflow: auto;

    .popup-icon {
      font-size: 25px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
