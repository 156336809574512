@import "../../../shared/Variables";

.button {
  height: 40px;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
}

.button.green {
  background-color: $sro-color;
}

.button.white {
  background-color: white;
}

.button.red {
  background-color: #ff515d;
}
