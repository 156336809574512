@import "../../../shared/Variables.scss";

.dropdown-multiple-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 20px;
  margin-top: 30px;
}

.dropdown-multiple {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  &--value {
    background-color: $sro-color;
    padding: 10px 35px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 14px;
    i {
      margin-left: 10px;
      padding-top: 2px;
      cursor: pointer;
    }
  }
}
