.icon-button {
  cursor: pointer;
  padding: 15px 25px;
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  z-index: 0;

  i {
    font-size: 24px;
  }

  &--label {
    display: inline-block;
    margin-right: 8px;
  }
}
