@import "../../../../../shared/Variables.scss";

.work-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  min-width: 130px;
  &--day {
    margin-bottom: 15px;
  }
  &--time {
    margin-top: 10px;
    padding: 15px 0;
    background: $sro-color;
    border-radius: 40px;
    width: 100%;
    text-align: center;

    .time {
    }
    .buttons {
      display: none;
      i {
        font-weight: 700;
        font-size: 17px;
        cursor: pointer;
        margin: 0;
      }
    }

    &:hover {
      .time {
        display: none;
      }
      .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
    }
  }
  i {
    font-size: 25px;
    margin-top: 10px;
  }
}
