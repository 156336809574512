@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@600&family=Roboto:wght@300;400;500;600;700&display=swap");
@import "./shared/Variables.scss";

body {
  margin: 0;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $sro-grey;
  min-height: 100vh;
  min-width: 100vw;
}
