.day-picker {
  &--label {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  &--buttons {
    display: flex;
    gap: 15px;
  }
}
