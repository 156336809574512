.client-settings {
  min-width: 1000px;
  width: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  &--name {
    display: flex;
    align-items: center;
    gap: 20px;
    .image {
      color: #ffffff;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 24px;
      background: limegreen;
    }

    .name {
      font-weight: 700;
      font-size: 14px;
    }
  }

  &--title {
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid #f3f5f6;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-top: 30px;
  }

  &--buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
