/** tailwind.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card-container {
    @apply bg-white flex flex-col items-center rounded-xl shadow-md p-6;
  }

  .normal-text {
    @apply not-italic font-normal text-sm leading-4 text-black;
  }

  .bold-text {
    @apply not-italic font-bold text-sm leading-4 text-black;
  }

  .divider-line {
    @apply border border-solid border-[#F3F3F3] mt-1 w-full;
  }
}
