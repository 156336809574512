.edit-se {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &--time {
    width: 85%;
    display: flex;
    gap: 15px;
  }
  &--specific-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    &__label {
      font-weight: 500;
      font-size: 14px;
      color: #071125;
    }
    &__lock {
      width: 30px;
      height: 30px;
      background: #f3f3f3;
      border-radius: 50%;
      color: #a2a2a2;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  &--specific-date__label {
    font-weight: 500;
    font-size: 14px;
    color: #071125;
    margin: 0;
  }
}
