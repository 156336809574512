@import "../../../shared/Variables";

.switch-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .switch-label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .switch {
    width: 55px;
    height: 25px;
    border-radius: 20px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    &.white {
      background-color: white;
    }
    &.switched {
      background-color: #cffff2;
    }
    &--dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $sro-color;
      position: absolute;
      left: 5px;
      top: 2.5px;
      transition: left 200ms;
    }
    &--dot.switched {
      left: 30px;
    }
  }
}
