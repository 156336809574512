.textarea {
  width: 85%;
  &--label {
    font-weight: 500;
    font-size: 14px;
    padding-left: 15px;
  }

  textarea {
    width: 100%;
    background: #f3f3f3;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    resize: none;
    height: 150px;
    outline: none;
    border: none;
    padding: 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }
}
