.schedule-wrapper {
  display: flex;
  width: 100%;
  .schedule {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    min-height: 85vh;
    margin-left: 1rem;
    &--controls {
      padding-bottom: 20px;
      border-bottom: 0.5px solid rgba(51, 51, 51, 0.2);
      &__title {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 24px;
        font-weight: 700;
        color: #182848;

        .arrows {
          display: flex;
          gap: 15px;
          .arrow {
            border-radius: 50%;
            background: #fafafa;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i {
              font-size: 13px;
              padding-top: 1px;
            }
          }
        }
      }
      &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        .staff-controls {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .schedule-wrapper {
    .schedule {
      width: 100%;
    }
  }
}
