@import "../../../shared/Variables.scss";

.schedule-event-box {
  height: fit-content;
  min-height: 350px;
  background-color: white;
  border-radius: 10px;
  position: sticky;
  top: 30px;
  padding: 25px;
  box-sizing: border-box;

  &.is-mobile {
    width: 100%;
  }

  &--title {
    font-size: 24px;
    font-weight: 700;
    color: #182848;
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__map {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f3f3f3;
      border-radius: 50%;
      padding: 10px;
      color: #000000;
      text-decoration: none;
    }
  }
  &--small-title {
    font-size: 14px;
    font-weight: 700;
    color: #071125;
    margin: 0;
    margin-bottom: 10px;
    i {
      font-style: normal;
      color: #ff000f;
    }
  }
  &--line {
    width: 100%;
    height: 1px;
    background-color: $sro-grey;
  }
  &--info {
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
    &__text {
      margin-right: 50px;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
  }
  &--text {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 24px;
  }

  &--rebook {
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: 700;
      color: #071125;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
    }
    &__date {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;
    }
  }
}
