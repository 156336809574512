@import "../../../../shared/Variables.scss";

.planned-event-settings {
  &--title {
    padding-left: 20px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid #f3f5f6;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  &--events {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    &__add {
      width: 200px;
      padding: 20px;
      border: 2px solid $sro-color;
      box-sizing: border-box;
      border-radius: 10px;
      height: 120px;
      p {
        margin: 0;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 14px;
      }
      i {
        cursor: pointer;
        font-size: 25px;
      }
    }
  }
}
