.schedule-overview {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;

  &--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &__title {
      display: flex;
      align-items: center;
      color: #182848;
      height: 30px;
      .strong {
        font-weight: 700;
        font-size: 24px;
        text-align: end;
        margin-right: 10px;
      }
      .arrows {
        display: flex;
        gap: 15px;
        .arrow {
          border-radius: 50%;
          background: #fafafa;
          height: 25px;
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i {
            font-size: 13px;
            padding-top: 1px;
          }
        }
      }
    }

    &__days {
      display: flex;
      align-items: center;
      gap: 30px;
      &__day {
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
      }
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }
  }

  &--schedule {
    overflow-x: auto;
    position: relative;
    max-height: 600px;
  }
}

button {
  background: #f3f3f3;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  outline: none;
  height: 50px;
  padding: 0 20px;
}
.schedule-overview {
  width: 100%;
}
.schedule-overview-page {
  // width: 100%;
}
.schedule-overview-container {
  display: flex;
  // padding: 0 20px;
  // width: 100%;
}
.schedule-control-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  margin: 0 0 0 20px;
  padding: 15px;
  background-color: white;
  border-radius: 1rem;
  .panel-button {
    width: 80%;
    margin: 15px 0;
    padding: 25px 20px;
  }
  .panel-text {
    text-align: center;
    font-size: 14px;
  }
}
.schedule-edit {
  margin: 0 20px;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
}
.download-file-btn {
  border-radius: 50%;
  cursor: pointer;
}
