@import "../../../../../shared/Variables.scss";

.time-off-settings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  &--time {
    padding: 15px 0;
    background: $sro-color;
    border-radius: 40px;
    width: 150px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    i {
      display: none;
      font-weight: 700;
      font-size: 17px;
      cursor: pointer;
      margin: 0;
    }
    &:hover {
      i {
        display: block;
      }
      .label {
        display: none;
      }
    }
  }
}
