.day-picker-button {
  border-radius: 79px;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  &.active {
    background: #f3f3f3;
  }
}
