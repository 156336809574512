.app-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    box-sizing: border-box;
    max-width: 1600px;
    width: 100%;
    padding: 0 40px;
    padding-bottom: 20px;
  }
}
