@import "../../shared/Variables.scss";

.toolbar {
  background-color: white;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &--logo {
    width: 200px;
    display: flex;
    justify-content: flex-start;
  }
  &--buttons {
    width: 200px;
    display: flex;
    justify-content: flex-end;
  }
}
