.user-selector {
  background-color: white;
  width: 300px;
  min-width: 300px;
  border-radius: 20px;
  height: fit-content;
  position: sticky;
  top: 20px;

  &--user-section {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    &__users {
      overflow-y: auto;
      height: 450px;
      max-height: 450px;
    }
    .add-button {
      margin-bottom: 10px;
    }
  }
}
