.overview-popup {
  cursor: default;
  width: 160px;
  height: auto;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -80px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10;

  &.under {
    top: 40px;
    bottom: auto;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &--description {
    font-size: 12px;
    font-weight: 700;
    color: #182848;
    margin: 0;
    margin-bottom: 15px;
  }

  &--info {
    display: flex;
    font-size: 12px;
    color: #182848;
    margin-bottom: 5px;
    align-items: center;
    font-weight: 400;
    i {
      display: flex;
      justify-content: center;
      width: 15px;
    }
    &__text {
      margin: 0;
      margin-left: 15px;
    }
  }
}
