.role-changer {
  display: flex;
  overflow: hidden;
  .choice {
    width: 50%;
    padding: 20px 40px;
    overflow: hidden;
    font-weight: 700;
    font-size: 14px;
    border-radius: 0px 20px 0px 0px;
    background: #11ffbd;
    &.nonActive {
      background: transparent;
    }
  }
  & :nth-child(1) {
    border-radius: 20px 0px 0px 0px;
  }
}
