@import "../../../shared/Variables";

.input-wrapper {
  width: 200px;

  .input-label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;

    &--error-message {
      font-size: 12px;
      font-weight: 700;
      margin-left: 15px;
      margin-bottom: 2px;
      color: #ff0000;
    }
  }

  .input {
    width: 100%;
    height: 40px;
    background-color: #f3f3f3;
    border: none;
    border-radius: 80px;
    box-sizing: border-box;
    font-family: "Roboto";
    padding: 0 15px;
    border: 1px solid $sro-grey;
    outline: none;
    &.invalid {
      border: 1px solid #ff0000;
      position: relative;
      animation: shake 0.1s linear;
    }

    &:focus {
      border: 1px solid #071125;
    }
    .input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #0711257c;
      opacity: 1; /* Firefox */
    }

    .input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #0711257c;
    }

    .input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #0711257c;
    }

    &.centered-text {
      text-align: center;
    }
    &.white {
      background-color: white;
    }
  }
}

@keyframes shake {
  0% {
    left: -5px;
  }
  100% {
    right: -5px;
  }
}
