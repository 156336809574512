@import "../../shared/Variables.scss";

.login {
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  background: white;
  display: flex;

  &--content {
    height: 100vh;
    width: 600px;
    background: $sro-grey;
    padding: 25px 15px;
    box-sizing: border-box;
    .inputs {
      width: 100%;

      &--error {
        color: red;
        font-size: 12px;
        padding-left: 15px;
        margin-bottom: 10px;
      }

      &--title {
        margin-top: 175px;
        margin-bottom: 50px;
        color: #071125;
        font-weight: 700;
        font-size: 54px;
      }
      &--form {
        & > * {
          margin-bottom: 15px;
        }

        &__buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &--logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__logo {
      background: url("../../assets/images/logo.svg");
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
