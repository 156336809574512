.overview-times {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  &--time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    min-width: 60px;
    height: 30px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    position: sticky;
    top: 0;
    background: white;
  }
  &--line {
    position: absolute;
    width: 1px;
    background-color: rgba(51, 51, 51, 0.2);
    top: 0;
    z-index: 0;
  }
  &--is-staff {
    min-width: 200px;
    height: 30px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    gap: 20px;
    &__option {
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
