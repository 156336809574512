.dropdown-wrapper {
  .dropdown-title {
    font-size: 12px;
    font-weight: 700;
    margin-left: 15px;
    margin-bottom: 2px;
  }

  .dropdown {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 40px;
    border: 0.5px solid rgba(223, 223, 227, 0.7);
    width: 190px;
    outline: none;
    color: #ecc08a83;
  }
}
