@import "../../../shared/Variables.scss";

.toolbar-buttons {
  display: flex;
  &__logout {
    background-color: $sro-grey;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 8px;
    i {
      font-size: 15px;
      padding-top: 1px;
    }
  }
}
