.add-wt {
  &--title {
    text-align: center;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
  }
  &--error {
    margin: 0;
    margin-top: 10px;
    text-align: center;
    font-size: 15px;
    color: #ff0000;
    height: 18.4px;
  }
  &--add-time {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 25px;
    i {
      cursor: pointer;
    }
  }
  &--save {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
